import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import RedditProjectPage from './pages/landingpage';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import ContactPage from './pages/ContactPage';
import DatasetExplanationPage from './pages/InfoPage';
import ReactGA from 'react-ga4';
import './index.css';

// Custom hook for page tracking
function usePageTracking() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
    console.log(`Page view sent for: ${location.pathname + location.search}`);
  }, [location]);
}
function App() {
  useEffect(() => {
    ReactGA.initialize('G-H2Q9C9ZQNR'); // Removed debug mode
    console.log('Google Analytics initialized');
  }, []);

  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  usePageTracking(); // Call the custom hook to track page views

  return (
    <Routes>
      <Route path="/" element={<RedditProjectPage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/dataset-explanation" element={<DatasetExplanationPage />} />
    </Routes>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);