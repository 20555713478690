import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Plot from 'react-plotly.js';
import { Search } from 'lucide-react';
import debounce from 'lodash/debounce';

const SubredditTopicAnalysis = () => {
  const [subreddits, setSubreddits] = useState([]);
  const [selectedSubreddit, setSelectedSubreddit] = useState('');
  const [topicData, setTopicData] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showPopularTopics, setShowPopularTopics] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 900);
  
  const fetchSubredditsByKeyword = useCallback(async (keyword) => {
    if (keyword.length < 3) {
      setSubreddits([]);
      return;
    }
    setIsLoading(true);
    try {
      const baseUrl = process.env.REACT_APP_API_URL || '';
      const response = await fetch(`${baseUrl}/api/subreddits?keyword=${keyword}`);
      const data = await response.json(); 
      setSubreddits(data.filtered_subreddits.slice(0, 5)); // Limit to 5 subreddits
    } catch (error) {
      console.error('Error fetching subreddits:', error);
      setError('Failed to fetch subreddits. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  const debouncedFetchSubreddits = useMemo(
    () => debounce(fetchSubredditsByKeyword, 300),
    [fetchSubredditsByKeyword]
  );

  useEffect(() => {
    debouncedFetchSubreddits(inputValue);
    return () => debouncedFetchSubreddits.cancel();
  }, [inputValue, debouncedFetchSubreddits]);

  const fetchTopicData = useCallback(async (subreddit) => {
    setIsLoading(true);
    try {
      const baseUrl = process.env.REACT_APP_API_URL || '';
      const response = await fetch(`${baseUrl}/api/subreddit_topics?subreddit=${subreddit}`);
      const data = await response.json();
      setTopicData(data);
    } catch (error) {
      console.error('Error fetching topic data:', error);
      setError(`Failed to fetch topic data for ${subreddit}. Please try again later.`);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (selectedSubreddit) {
      fetchTopicData(selectedSubreddit);
    }
  }, [selectedSubreddit, fetchTopicData]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setShowPopularTopics(true);
  };

  const handleSubredditSelect = (subreddit) => {
    setSelectedSubreddit(subreddit);
    setInputValue(''); // Clear the input value
    setSubreddits([]); // Clear the subreddit list
    setShowPopularTopics(false); // Hide popular topics after selection
  };

  const SubredditDropdown = React.memo(() => {
    if (!subreddits.length && inputValue.length < 3 && showPopularTopics) {
      return (
        <ul className="mt-2 bg-gray-100 border border-gray-300 rounded-md p-2">
          <li className="text-gray-600">Popular subreddits:</li>
          {['AskReddit', 'funny', 'gaming', 'aww', 'pics'].map(subreddit => (
            <li
              key={subreddit}
              onClick={() => handleSubredditSelect(subreddit)}
              className="px-2 py-1 hover:bg-gray-200 cursor-pointer text-blue-600"
            >
              r/{subreddit}
            </li>
          ))}
        </ul>
      );
    }

    if (!subreddits.length) return null;

    return (
      <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded-md shadow-lg">
        {subreddits.map(subreddit => (
          <li
            key={subreddit}
            onClick={() => handleSubredditSelect(subreddit)}
            className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
          >
            r/{subreddit}
          </li>
        ))}
      </ul>
    );
  });

  const TopicPlot = React.memo(() => {
    if (!topicData.length) return null;
  
    const sortedData = [...topicData].sort((a, b) => b.post_count - a.post_count);
    const displayData = sortedData.slice(0, isMobile ? 5 : 20);
  
    // Function to split long labels at the space closest to the middle
    const splitLabel = (label, maxLength = 20) => {
      if (isMobile && label.length > maxLength) {
        const midpoint = Math.floor(label.length / 2);

        // Find the last space before or at the midpoint
        const lastSpaceIndex = label.lastIndexOf(' ', midpoint);
        // If no space is found, look for the first space after the midpoint
        const firstSpaceIndex = label.indexOf(' ', midpoint);

        if (lastSpaceIndex === -1 && firstSpaceIndex === -1) {
          // No spaces found, return the label as is (fallback)
          return label;
        }

        // Use the nearest space to the midpoint for splitting
        const splitIndex = lastSpaceIndex !== -1 ? lastSpaceIndex : firstSpaceIndex;

        return label.slice(0, splitIndex) + '<br>' + label.slice(splitIndex + 1);
      }
      return label;
    };

  
    // Adjust the tickangle based on the number of topics
    const tickAngle = 45
    
    const data = [{
      x: displayData.map(item => splitLabel(item.topic)),
      y: displayData.map(item => item.post_count),
      type: 'bar',
      marker: {
        color: 'rgb(158,202,225)',
        opacity: 0.6,
      },
    }];
  
    const layout = {
      title: {
        text: `Number of posts per topic in<br>r/${selectedSubreddit}`,
        font: {
          size: 18,
          family: 'Arial, sans-serif',
          weight: 'bold'
        },
      },
      xaxis: { 
        title: {
          text: 'Topics',
          font: {
            size: isMobile ? 18 : 20,
            family: 'Arial, sans-serif'
          }
        },
        tickangle: tickAngle,
        tickfont: {
          size: isMobile ? 12 : 15,
          family: 'Arial, sans-serif'
        },
        automargin: true,
      },
      yaxis: { 
        tickformat: '~s',
        tickfont: {
          size: 15,
          family: 'Arial, sans-serif'
        }, 
        automargin: true,
      },
      autosize: true,
      margin: { l: 35, r: 10, b: isMobile ? 200 : 250, t: 100, pad: 4 }
    };
  
    return (
    <Plot
      data={data}
      layout={{
        ...layout,
        dragmode: !isMobile, // Disable zooming and panning
        scrollzoom: !isMobile // Ensure scroll zooming is also disabled
      }}
      style={{ width: '100%', height: '100%', minHeight: isMobile ? '400px' : '500px' }}
      useResizeHandler={true}
      config={{ 
        responsive: true, 
        displayModeBar: !isMobile 
      }}
      onClick={(data) => {
        if (data.points && data.points.length > 0) {
          const point = data.points[0];
          // Handle click event here, e.g., display point information
          console.log(point);
        }
      }}
/>

    );
  });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 900);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="w-full h-full flex flex-col p-2 md:p-4 bg-gray-50" style={{ minHeight: '500px' }}>
      <form onSubmit={(e) => e.preventDefault()} className="mb-4 relative">
        <div className="relative">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          <input
            type="text"
            placeholder="Enter a subreddit name"
            value={inputValue}
            onChange={handleInputChange}
            className="w-full pl-10 pr-4 py-2 text-sm md:text-base border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white"
          />
        </div>
        <SubredditDropdown />
      </form>

      {isLoading && (
        <div className="flex items-center justify-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
        </div>
      )}

      {!isLoading && !selectedSubreddit && (
        <div className="text-center text-gray-600 mt-8">
          <p>Search for a subreddit above to see its topic analysis.</p>
        </div>
      )}

      {selectedSubreddit && !isLoading && (
        <div className="flex-grow" style={{ minHeight: '500px' }}>
          <TopicPlot />
        </div>
      )}
    </div>
  );  
};

export default SubredditTopicAnalysis;